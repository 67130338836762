import { render, staticRenderFns } from "./MoleculeAvailabilitySpaceFilterResultBuilding.vue?vue&type=template&id=1a27993c&"
import script from "./MoleculeAvailabilitySpaceFilterResultBuilding.vue?vue&type=script&lang=js&"
export * from "./MoleculeAvailabilitySpaceFilterResultBuilding.vue?vue&type=script&lang=js&"
import style0 from "./MoleculeAvailabilitySpaceFilterResultBuilding.vue?vue&type=style&index=0&id=1a27993c&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "@/lang/MoleculeAvailabilitySpaceFilterResult.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fapp%2Fcomponents%2Fmolecules%2Fproject%2Fbuilding%2FMoleculeAvailabilitySpaceFilterResultBuilding.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MoleculesProjectBuildingMoleculeSidebarResultSpaceDetails: require('/app/components/molecules/project/building/MoleculeSidebarResultSpaceDetails.vue').default})
