import { render, staticRenderFns } from "./MoleculeAvailabilitySpaceFilterResultHeader.vue?vue&type=template&id=0080fb08&scoped=true&"
import script from "./MoleculeAvailabilitySpaceFilterResultHeader.vue?vue&type=script&lang=js&"
export * from "./MoleculeAvailabilitySpaceFilterResultHeader.vue?vue&type=script&lang=js&"
import style0 from "./MoleculeAvailabilitySpaceFilterResultHeader.vue?vue&type=style&index=0&id=0080fb08&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0080fb08",
  null
  
)

/* custom blocks */
import block0 from "@/lang/MoleculeAvailabilitySpaceFilterResultHeader.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fapp%2Fcomponents%2Fmolecules%2Fproject%2Fbuilding%2FMoleculeAvailabilitySpaceFilterResultHeader.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports